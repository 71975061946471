import React from "react"
import { Helmet } from "react-helmet"

export default () => (
    <div>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Shit Fuck</title>
        <link rel="canonical" href="https://www.calvinsmith.xyz/404/" />
        </Helmet>
        <p>Oh shit fuck fuck fuck, Press Back!!!</p>   
    </div>

)
